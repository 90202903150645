import React, { useState, useRef } from "react";

import { SERVICE_URL } from "../../utils/rankTrackerService";

const Iframe = ({ searchID }: { searchID: string }) => {
  const [height, setHeight] = useState<number>(900);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleLoad = () => {
    const iframeBodyHeight =
      iframeRef.current?.contentWindow?.document.body?.scrollHeight;

    if (iframeBodyHeight) {
      setHeight(iframeBodyHeight + 50);
    }
  };

  return (
    <iframe
      className="html-results-frame"
      ref={iframeRef}
      src={`${SERVICE_URL}/results/${searchID}/`}
      onLoad={handleLoad}
      height={`${height}px`}
      width="100%"
      frameBorder="0"
    />
  );
};

export default Iframe;
