import { Device } from "components/RankTracker/RankTrackerHelpers";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { DeepNonNullable } from "ts-essentials";
import { MockBrowserDataQuery } from "../../graphqlTypes";
import { StaticImage } from "gatsby-plugin-image";

const Placeholder: React.FC = ({ children }) => (
  <div className="w-full h-full my-auto text-center flex justify-center flex-col">
    <p className="text-white opacity-30 text-3xl py-16 px-8 whitespace-pre-wrap">
      {children}
    </p>
  </div>
);

const Loading: React.FC = ({ children }) => (
  <div className="w-full h-full my-auto text-center flex justify-center flex-col">
    <p className="text-white opacity-30 text-3xl py-16 px-8 animate-pulse whitespace-pre-wrap">
      {children}
    </p>
  </div>
);

const MockBrowser: React.FC<{ loading: boolean; device: Device }> = ({
  children,
  loading,
  device,
}) => {
  const pageData = useStaticQuery<
    DeepNonNullable<MockBrowserDataQuery>
  >(graphql`
    query MockBrowserData {
      strapiRankTracker {
        MockupBrowser {
          idleText
          loadingText
        }
      }
    }
  `);

  const { idleText, loadingText } = pageData.strapiRankTracker.MockupBrowser;

  return (
    <div
      className="pt-8 self-center w-full relative inline-block overflow-hidden bg-accuRankerPurpleOpacity-light rounded-3xl"
      /** Give a little more space to the Iframe (desktop only) */
      style={{ width: children && device === "desktop" ? "104%" : "100%" }}
    >
      <div className="browser-navigation-bar block h-8 absolute top-0 p-1 pt-2 pl-4 w-full border-b-2 border-accuRankerPurpleOpacity-dark bg-accuRankerPurpleOpacity-dark text-left">
        <i
          className={`inline-block h-3 w-3 rounded-lg bg-accuRankerPurpleOpacity-light mx-1 my-1 ${
            loading && "animate-bounce"
          }`}
        />
        <i
          className={`inline-block h-3 w-3 rounded-lg bg-accuRankerPurpleOpacity-light mx-1 my-1 ${
            loading && "animate-bounce"
          }`}
          style={{ animationDelay: "0.15s" }}
        />
        <i
          className={`inline-block h-3 w-3 rounded-lg bg-accuRankerPurpleOpacity-light mx-1 my-1 ${
            loading && "animate-bounce"
          }`}
          style={{ animationDelay: "0.30s" }}
        />
      </div>
      <div className="browser-container h-full w-full overflow-y-auto overflow-x-hidden">
        {!loading && (
          <>
            {children ? (
              children
            ) : (
              <>
                <StaticImage
                  src={
                    "../images/RankTracker/google-preview-faded-white-min.png"
                  }
                  alt={"Google preview"}
                  width={1220}
                  placeholder={"none"}
                />
                <Placeholder>{idleText}</Placeholder>
              </>
            )}
          </>
        )}
        {loading && <Loading>{loadingText}</Loading>}
      </div>
    </div>
  );
};

export default MockBrowser;
