import React, { ComponentProps } from "react";
import classnames from "classnames";
import { PropTypes } from "react-places-autocomplete";
import MobileIcon from "../../icons/Mobile";
import DesktopIcon from "../../icons/Desktop";
import Modal from "../../ui-components/Modal";

export type Device = "mobile" | "desktop";

export type DeviceSwitchProps = {
  onClick: (device: Device) => void;
  active: Device;
  devices: {
    name: Device;
    body: JSX.Element;
  }[];
};

export const DeviceSwitchData: DeviceSwitchProps["devices"] = [
  {
    body: <DesktopIcon className="h-7 w-7 m-auto" aria-hidden="true" />,
    name: "desktop",
  },
  {
    body: <MobileIcon className="h-7 w-7 m-auto" aria-hidden="true" />,
    name: "mobile",
  },
];

export const PlacesRender: PropTypes["children"] = ({
  getInputProps,
  getSuggestionItemProps,
  suggestions,
  loading,
}) => (
  <div className="autocomplete-root w-full relative">
    {loading && (
      <p className="text-accuRankerOrange-300 absolute -top-6">Loading</p>
    )}
    <input
      {...getInputProps()}
      placeholder="Search for location"
      className="input-border w-full"
    />
    {suggestions.length > 0 && (
      <div className="top-full bg-white rounded absolute z-10 w-full py-1 my-2">
        {suggestions.map(suggestion => (
          <div
            {...getSuggestionItemProps(suggestion, {
              className:
                "hover:bg-accuRankerPurple-500 hover:text-white cursor-pointer px-2 py-3 w-full block",
            })}
          >
            <span>{suggestion.description}</span>
          </div>
        ))}
      </div>
    )}
  </div>
);

export const DeviceSwitch = ({
  onClick,
  active,
  devices,
}: DeviceSwitchProps) => (
  <span className="relative z-0 inline-flex shadow-sm rounded-xl">
    {devices.map(({ name, body }) => (
      <button
        key={name}
        type="button"
        onClick={() => onClick(name)}
        className={classnames(
          "relative inline-flex h-12 w-16 items-center px-2 py-2 first:rounded-l-xl last:rounded-r-xl border border-white text-sm font-medium",
          {
            "bg-accuRankerPurple-500 text-accuRankerPurpleOpacity-light":
              active === name,
            "text-accuRankerPurple-500 bg-white": active !== name,
          }
        )}
      >
        {body}
      </button>
    ))}
  </span>
);

export const RequestLimitModal: Pick<
  ComponentProps<typeof Modal>,
  "title" | "description" | "closeText" | "actionText"
> = {
  title: <p>You spent your 5 free requests</p>,
  description:
    "Consider making a subscription and learn more about the best SERP tool",
  closeText: "Close",
  actionText: {
    text: "Subscribe",
    action: () => console.log("All good"),
  },
};
