import React, { useState } from "react";

import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import { locales, ValueLabel } from "../../utils/grumpUtils";
import { useEffect } from "react";
import Button from "../../ui-components/Button";

import MockBrowser from "../../ui-components/MockBrowser";
import RankTrackerService from "../../utils/rankTrackerService";
import Modal from "../../ui-components/Modal";
import {
  Device,
  DeviceSwitch,
  DeviceSwitchData,
  PlacesRender,
  RequestLimitModal,
} from "./RankTrackerHelpers";
import Iframe from "./Iframe";

/**
 *
 * Helpers
 *
 */

type ValidationKeys = "keyword" | "countrySelect";

const InitialValidationState: {
  [key in ValidationKeys]: boolean;
} = {
  keyword: false,
  countrySelect: false,
} as const;

/**
 *
 * RankTracker Component
 *
 */
const RankTracker = () => {
  const [device, setDevice] = useState<Device>("desktop");
  const [response, setResponse] = useState<ValueLabel[] | null>(null);
  const [selected, setSelected] = useState<ValueLabel | null>(null);
  const [gmap, setGmap] = useState<boolean>(false);
  const [location, setLocation] = useState<string>("");
  const [searchID, setSearchID] = useState<string | null>(null);
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [genericError, setGenericError] = useState<string | null>(null);
  const [upgradeModal, setUpgradeModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validation, setValidationError] = useState(InitialValidationState);
  /** To enable react-select arror styling */
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Fetches supported locale from AccuRanker's server
   */
  const getData = async () => {
    const data = await RankTrackerService.getAllLocales();

    if (data.error !== null) {
      setGenericError(data.error);
    } else {
      setResponse(data.data);
    }
  };

  /**
   * Fetched the generated search ID
   * and wait until the result is properly fetch from
   * AccuRanker's server
   */
  const getRankID = async () => {
    if (keyword && locales.length > 0 && device && selected !== null) {
      setLoading(true);
      try {
        const response = await RankTrackerService.performSearch({
          device,
          keyword,
          location,
          locale: selected.value,
        });

        if (response.status === 406) {
          setUpgradeModal(true);
          return;
        }

        if (response.status !== 200) {
          throw new Error("[ranker page]: Status code is not 200");
        }

        const data = await response.json();

        const { id: searchID } = data;

        await RankTrackerService.waitUntilReady(searchID, device);

        setSearchID(searchID);
      } catch (e) {
        setGenericError("Something happened, please try again 😿");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getData();

    /**
     * Fix how Google Maps API is loaded
     * Issue: https://github.com/hibiken/react-places-autocomplete/issues/195
     */
    (window as any).initMap = () => setGmap(true);
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBEKeKFDA3ABcaAsxnhizQcHAKa_Lk7fKE&libraries=places&callback=initMap`;
    document
      ?.querySelector(`body`)
      ?.insertAdjacentElement(`beforeend`, gmapScriptEl);
  }, []);

  const submitForm = () => {
    setValidationError(InitialValidationState);
    const newValidation = { ...InitialValidationState };

    if (!keyword) {
      newValidation.keyword = true;
      setValidationError(newValidation);
      return;
    }

    if (!selected?.value) {
      newValidation.countrySelect = true;
      setValidationError(newValidation);
      return;
    }

    getRankID();
  };

  return (
    <div className="flex flex-col gap-y-10">
      <Modal
        isOpen={upgradeModal}
        onClose={() => setUpgradeModal(false)}
        title={RequestLimitModal.title}
        description={RequestLimitModal.description}
        closeText={RequestLimitModal.closeText}
        actionText={RequestLimitModal.actionText}
      />
      {genericError && (
        <div className="w-full text-center m-auto h-10">
          <p className="text-accuRankerOrange-300">{genericError}</p>
        </div>
      )}
      {/* Form Elements */}
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="relative w-full">
          {validation.keyword && (
            <p className="text-accuRankerOrange-300 absolute -top-6">
              Please fill this
            </p>
          )}
          <input
            type="text"
            placeholder="Enter Keyword"
            className="input-border w-full"
            onChange={e => setKeyword(e.target.value)}
          />
        </div>
        {/* Location Dropdown  */}
        {response && (
          <div className="w-full h-full" style={{ maxWidth: "500px" }}>
            <div className="relative w-full">
              {validation.countrySelect && (
                <p className="text-accuRankerOrange-300 absolute -top-6">
                  Please select country
                </p>
              )}
              <Select
                isLoading={response && response.length === 0}
                value={selected}
                isSearchable={true}
                options={response}
                placeholder="Select Country"
                onMenuOpen={() => setIsOpen(true)}
                onMenuClose={() => setIsOpen(false)}
                styles={{
                  control: provided => ({
                    ...provided,
                    borderRadius: "0.75rem",
                    height: "3rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }),
                  placeholder: provided => ({
                    ...provided,
                    whiteSpace: "nowrap",
                  }),
                  dropdownIndicator: provided => {
                    console.log(provided);
                    return {
                      ...provided,
                      transition: "all 0.4s ease-out",
                      ...(isOpen && { transform: "rotate(180deg)" }),
                      // transform: isOpen ? "" : "rotate (180deg)",
                    };
                  },
                }}
                onChange={e => setSelected(e)}
              />
            </div>
          </div>
        )}
        {gmap && (
          <div className="relative w-full">
            <PlacesAutocomplete
              value={location}
              onChange={value => setLocation(value)}
              searchOptions={{
                types: ["(regions)"],
                componentRestrictions: selected
                  ? { country: selected.value.split("-")[1] }
                  : undefined,
              }}
            >
              {PlacesRender}
            </PlacesAutocomplete>
          </div>
        )}
        {/* Desktop vs Mobile Button Group */}
        <DeviceSwitch
          onClick={e => setDevice(e)}
          active={device}
          devices={DeviceSwitchData}
        />
      </div>
      <div className="md:m-auto">
        <Button
          type="submit"
          onClick={submitForm}
          className="w-80 py-4"
          color="orange"
          disabled={loading}
        >
          {loading ? "Searching..." : "Search Now!"}
        </Button>
      </div>
      <MockBrowser device={device} loading={loading}>
        {searchID && <Iframe searchID={searchID} />}
      </MockBrowser>
    </div>
  );
};

export default RankTracker;
