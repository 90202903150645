import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import BonnetCurves from "../images/Curves/FinishingDouble2Single.svg";
import RankTracker from "../components/RankTracker/RankTracker";
import { default as BulletSectionComp } from "../ui-components/BulletSection";
import { RankTrackerDataQuery } from "../../graphqlTypes";
import { DeepNonNullable } from "ts-essentials";
import { mdxBody } from "@utils/mdxUtils";
import { MDXRenderer } from "gatsby-plugin-mdx";

const RankTrackerPage = () => {
  const pageData = useStaticQuery<
    DeepNonNullable<RankTrackerDataQuery>
  >(graphql`
    query RankTrackerData {
      strapiRankTracker {
        childStrapiRankTrackerHeaderTitle {
          childMdx {
            body
          }
        }
        Header {
          title
          subtitle
        }
        BulletSection {
          title
          subtitle
          rightColumn: RightColumn {
            id
            title
            subtitle
          }
          leftColumn: LeftColumn {
            id
            title
            subtitle
          }
        }
      }
    }
  `);

  const {
    BulletSection,
    Header,
    childStrapiRankTrackerHeaderTitle: headerTitle,
  } = pageData.strapiRankTracker;

  return (
    <Layout>
      <Seo title="Google SERP Checker • Free Online Tool » Location Specific SERPs" />
      <div className="div-block mt-10">
        <div className="flex flex-col items-start md:items-center text-center md:w-full md:p-20 bg-accuRankerPurple-900">
          <div id="title" className="text-left md:text-center text-white">
            <MDXRenderer>{mdxBody(headerTitle)}</MDXRenderer>
          </div>
          <div className="mt-10">
            <p className="text-left md:text-center text-base max-w-3xl text-gray-400">
              {Header.subtitle}
            </p>
          </div>
        </div>
      </div>
      <div className="div-block max-w-7xl z-10 relative xl:mx-auto overflow-visible">
        {/* SERP Checker Panel */}
        <div className="rounded-2xl bg-accuRankerPurple-500 mb-4 mt-12 md:mt-0 lg:mx-auto px-8 py-8">
          <RankTracker />
        </div>
        {/* Bonnet Section */}
        <div className="relative pb-40 mt-12 md:-mt-4">
          <img
            src={BonnetCurves}
            className="bonnet-background-curve absolute h-full mx-auto left-0 right-0 z-0"
            alt="Bonnet Curvers Image"
          />
          <div className="md:pt-60 bonnet">
            <BulletSectionComp {...BulletSection} />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default RankTrackerPage;
