import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./Button";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  description: string | JSX.Element;
  closeText: string | JSX.Element;
  actionText?: {
    text: string | JSX.Element;
    action: () => void;
  };
};

/**
 *
 * Copied the Modal from HeadlessUI official documentation
 * Source: https://headlessui.dev/react/dialog
 * Not the most clean code
 *
 */
const Modal = ({
  isOpen,
  onClose,
  title,
  description,
  closeText,
  actionText,
}: ModalProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
    >
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{description}</p>
            </div>

            <div className="mt-4 flex flex-row gap-x-2">
              <Button color="lightPurple" onClick={onClose}>
                {closeText}
              </Button>
              {actionText && (
                <Button color="orange" onClick={actionText.action}>
                  {actionText.text}
                </Button>
              )}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
