export const SERVICE_URL = `/api/rank`;

export type ValueLabel = {
  value: string;
  label: string;
};

export type SearchParams = {
  keyword: string;
  location: string;
  locale: string;
  device: string;
};

type LocalePoint = {
  tld: string;
  description: string;
};

type RankEngineResponse = {
  engine: string;
  supported: boolean;
  locales: {
    [locale: string]: LocalePoint;
  };
}[];

let POLL_INTERVAL = 5000; // every second
const MAX_POLL_DURATION = 3 * 60 * 1000; // 3 minutes in ms

class RankTrackerService {
  static getAllLocales = (): Promise<
    ({ data: ValueLabel[] } & { error: null }) | { error: string }
  > => {
    return fetch(SERVICE_URL + "/engines/")
      .then(response => response.json())
      .then((responseData: RankEngineResponse) => {
        const data = responseData.filter(
          currentEngine => currentEngine.engine === "google"
        );

        const { locales } = data[0];
        const countries: ValueLabel[] = [];

        /** Prepare values for react-select */
        Object.keys(locales).forEach(locale => {
          countries.push({
            value: locale,
            label: locales[locale].description,
          });
        });

        countries.sort((a, b) => a.label.localeCompare(b.label));

        return {
          data: countries,
          error: null,
        };
      })
      .catch(er => ({
        error: er.toString(),
      }));
  };

  static waitUntilReady = (searchId: string, device: string) => {
    const startTime = new Date().getTime();
    return new Promise((resolve, reject) => {
      function iteration() {
        POLL_INTERVAL = Math.max(1000, POLL_INTERVAL - 500);
        const delta = new Date().getTime() - startTime;
        if (delta > MAX_POLL_DURATION) {
          reject(new Error("Unable to load results. Timeout reached."));
        }
        const url = `${SERVICE_URL}/results/${searchId}/`;
        fetch(url)
          .then(response => {
            if (response.status === 200) {
              resolve(true);
            }
            if (response.status === 404) {
              throw new Error("[ranker page]: Retrying");
            }
            reject(new Error("[ranker page]: Status <> 200"));
          })
          .catch(() => {
            setTimeout(iteration, POLL_INTERVAL);
          });
      }
      setTimeout(iteration, POLL_INTERVAL);
    });
  };

  static performSearch(data: SearchParams) {
    const controller = new AbortController();

    /** Timeout  */
    setTimeout(() => controller.abort(), MAX_POLL_DURATION);

    const formData = new FormData();
    formData.append("keyword", data.keyword);
    formData.append("locale", data.locale);
    formData.append("geo", data.location);
    formData.append("limit", "100");
    formData.append("device", data.device);
    return fetch(`${SERVICE_URL}/keywords/`, {
      method: "POST",
      body: formData,
      signal: controller.signal,
    });
  }
}

export default RankTrackerService;
